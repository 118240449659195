/* allow-no-unused-vars */
import axios from 'axios' 
import url from '@/helpers/url'
import getToken from '../../helpers/getToken'

export const state = {
    debateArticle : []
}

export const actions = {
    addNewDebateArticle({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/debate-article-create`, data, {
                headers: {
                    'Authorization':`${getToken()}`
                }
            }).then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addThesis({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = getToken()
            axios.post(`${url()}/api/blog/add-thesis`, data, )
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addAntiThesis({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/add-anti-thesis`, data,
            {
                headers: {
                    'Authorization':`${getToken()}`
                }
            })
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addSynthesis({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/add-synthesis`, data, {
                headers: {
                    'Authorization':`${getToken()}`
                }
            })
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    fetchApprovedDebateArticle({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/api/blog/approved-debate-articles`, data)
                .then(response => {
                    commit('ADD_DEBATE_ARTICLE',response.data)
                    resolve(response)
                }).catch(err => reject(err))
        })
    }
}

export const mutations = {
    ADD_DEBATE_ARTICLE(state, debateArticle) {
        state.debateArticle = debateArticle
    }
}

export const getters = {
    debateArticle: state => state.debateArticle
}