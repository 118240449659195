import axios from 'axios' 
import url from '@/helpers/url'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'
import getToken from '../../helpers/getToken'

export const state = {
    articles:[]
}

export const actions = {
    addArticle({commit}, data) {
        return new Promise((resolve, reject) => {
            
            axios.post(`${url()}/api/blog/add-article`,data,{
                headers: {
                    'Authorization':`${getToken()}`
                }
            })
                .then(response => {
                    // commit('ADD_ARTICLE', response.data)
                    resolve(response)
                }).catch( err => reject(err))
        })
    },
    fetchApprovedArticle({commit}){
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/api/blog/approved-articles`,{
                headers: {
                    'Authorization':`${getToken()}`
                }
            })
                .then( response => {
                    commit('ADD_ARTICLE', response.data)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addComment({commit}, data){
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/add-article-comment`, data,{
                headers: {
                    'Authorization':`${getToken()}`
                }
            })
                .then( response => {
                    // commit('ADD_COMMENT', response.data)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },

}

export const mutations = {
    ADD_ARTICLE(state, article) {
        state.articles = article
    }
}
export const getters = {
    articles:state => state.articles
}