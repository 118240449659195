import Vue from "vue";
import Vuex from "vuex";
import * as auth from "./modules/auth";
import * as debate from "./modules/debate";
import * as challenge from "./modules/challenge";
import * as article from "./modules/article";
import * as event from "./modules/event";
import * as entrepreneur from "./modules/entreprenure";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    debate,
    challenge,
    article,
    event,
    entrepreneur,
  },
});
