<template>
    <nav class="navbar navbar-expand-lg fixed-top bg-dark  navbar-dark">
  <div class="container">
      <router-link class="navbar-brand" to="/" >
            <img height="70" src="../assets/rsz_afcfta_visions_2.png"   alt=""> 
            <!-- <strong>AfCFTA Visions</strong> -->
      </router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse  navbar-collapse " id="navbarNav">
     <ul class="navbar-nav  ml-auto">
           <li class="nav-item">
        <router-link to="/" class="nav-link text-white" >Home</router-link>
      </li>
          <li class="nav-item">
        <router-link to="/challenge" class="nav-link text-white" >ContestS</router-link>
      </li>
      <li class="nav-item active">
        <router-link class="nav-link text-white" to="/debates">Debates</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link text-white" to="/articles">Articles</router-link>
      </li>
       <li class="nav-item">
        <router-link to="/AfCFTA_resources" class="nav-link text-white" >AfCFTA Resources</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/boma" class="nav-link text-white" >Boma</router-link>
      </li>
      <!-- <li class="nav-item" v-if="!user">
       
      </li> -->
        <li class="nav-item" v-if="!user">
          <div class="d-flex pt-2">
            <a class=" text-white" href='/redirect?service=register'>Signup</a> <span class="px-1 text-white">/</span>
            <a class=" text-white" href='/redirect?service=login'>Login</a>
          </div>
        </li>
        <li class="nav-item" v-if="user">
          <a @click="logout" class="nav-link text-white" 
          :href="`${url()}/accounts/logout?${service()}`">Logout</a>
        </li>
    </ul>
    </div>
  </div>
</nav>
</template>

<script>
import VueSessionStorage from 'vue-sessionstorage'
import Storage from '../helpers/storage'
import url from './../helpers/url'
import service from './../helpers/service'
import $ from "jquery";
$(window).scroll(function(){
 $('nav').toggleClass('scrolled', $(this).scrollTop() > 0);
 });
 export default {
  data() {
    return {
      user : null
    }
  },
  methods: {
    url() {
      return url()
    },
    service() {
      return service()
    },
    logout() {
      Storage.removeAll()
      this.$session.remove('entity')
    },
    getUser() {
      this.user = this.$session.get('entity')
    },
  },
  created() {
    this.getUser()
  }
}

</script>


<style scoped>
    .nav-link{
    text-transform:uppercase;
    margin-right:30px;
    color: #fff;
    }
    a{
      text-transform:uppercase;
    }
    .bg-dark{
      background:transparent !important;

    }
    .bg-dark.scrolled{
      background:#1a1d20 !important;
    }
    @media screen and (max-width: 600px) {
      .navbar-collapse {
          background:#1a1d20;
          margin: -.5rem -1rem;
          padding: .5rem 1rem;
          opacity:0.8;
      }
      ul {
        text-align: center;
      }
      .nav-brand strong{
        font-size:1.1em;
      }
    strong{
      display: none;
    }
}
 
    
</style>