<template>
  <div id="app">
    <router-view/>
    <!-- <button @click="topFunction" id="myBtn" title="Go to top">Top</button> -->
  </div>
</template>
<script>
 window.onscroll = function() {function scrollFunction() {
      var mybutton = document.getElementById("myBtn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    mybutton.style.display = "block";
    } else {
    mybutton.style.display = "none";
    }
  }};

export default {
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  name:'afcfta-visions'
}
</script>
<style>
.preview_img{
  width: 121px;
  height: 121px;
}
.base-image-input {
    display: block;
    width: 121px;
    height: 121px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
}
.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #dee2e6;
  }
  .file-input {
      display: none;
  }

@font-face {
    font-family: 'Segoe UI';
    src: url('./assets/new/fonts/segoeui.eot');
    src: local("Segoe UI"),
         local("Segoe"),
         local("Segoe WP"),
         url('./assets/new/fonts/segoeui.eot?#iefix') format('embedded-opentype'),
         url('./assets/new/fonts/segoeui.woff') format('woff'),
         url('./assets/new/fonts/segoeui.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Semibold';
    src: url('./assets/new/fonts/seguisb.eot');
    src: local("Segoe Semibold"),
         local("Segoe WP Semibold"), 
         url('./assets/new/fonts/seguisb.eot?#iefix') format('embedded-opentype'),
         url('./assets/new/fonts/seguisb.woff') format('woff'),
         url('./assets/new/fonts/seguisb.svg#SegoeUISemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Bold';
    src: url('./assets/new/fonts/segoeuib.eot');
    src: local("Segoe Bold"),
         local("Segoe WP Bold"),
         url('./assets/new/fonts/segoeuib.eot?#iefix') format('eot'),
         url('./assets/new/fonts/segoeuib.woff') format('woff'),
         url('./assets/new/fonts/segoeuib.svg#SegoeUIBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Light';
    src: url('/light/segoeuil.eot');
    src: local("Segoe UI Light"),
         local("Segoe WP Light"),
         url('./assets/new/fonts/segoeuil.eot?#iefix') format('embedded-opentype'),
         url('./assets/new/fonts/segoeuil.woff') format('woff'),
         url('./assets/new/fonts/segoeuil.svg#SegoeUILight') format('svg');
    font-weight: normal;
    font-style: normal;
}

#app{
  overflow:hidden;
  font-family: Segoe UI !important;
  white-space: pre-wrap !important;
}
nav {
 font-family: Segoe UI Bold; 
}
nav .links li a{
  font-family: Segoe UI Bold!important;
  font-weight: bold;
}
.header{
  font-weight: bolder;
}
/* h2{
  font-family: Segoe UI Bold;
} */
/* #myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
} */

#myBtn:hover {
  background-color: #555;
}
#page-container-red{
    background-color:#ba2428 !important;
}
#page-container-white{
    background-color:#fff !important;
}
.myBtn{
    background-color:#ba2428;
    padding:1em 2em;
    color:#fff;
    text-decoration: none;
    border: 1px solid #ba2428;
    transition: all 0.5s;
}
</style>
