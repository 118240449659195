import axios from 'axios'
import url from '@/helpers/url.js'
import Storage from '@/helpers/storage.js'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'
import service from '../../helpers/service'
var convert = require('xml-js')

export const state = {
    currentUser:Storage.get('currentUser') || {},
    token:Storage.get('token')
}
export const actions = {
    contactUs({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/contact-form`, data)
                .then(response => {
                    resolve(response)
                }).catch(err => {
                    reject(err)
                })
        })
    },
   
    validateServiceTicket({commit,dispatch}, ticket) {
        
        return new Promise((resolve, reject) => {
            // console.log(`${url()}/accounts/serviceValidate?${service()}&ticket=${ticket}`)
            axios.get(`${url()}/accounts/serviceValidate?${service()}&ticket=${ticket}`)
                .then(response => {
                        const xml = response.data
                        const data = convert.xml2json(xml, {compact: true, spaces: 4});
                        const user = JSON.parse(data)
                        console.log(user)
                        const email = user["cas:serviceResponse"]["cas:authenticationSuccess"]["cas:user"]._text
                        axios.defaults.headers.common['Authorization'] = email
                        // Vue.prototype.$session.set('entity',email)
                       dispatch('getUserDetails',email).then( response =>  resolve(response))
                    
            }).catch( err => reject(err))
        })
   },
   getUserDetails({commit}, email) {
    return new Promise((resolve, reject) => {
        axios.get(`${url()}/api/account/detail/${email}`)
        .then(response => {
            if(response) {

                const user = response.data

                commit('set_currentUser', user)
                commit('set_token', user.individual.email)
            }
            resolve(response)
        }).catch(err => reject(err))
    })
}
}

export const mutations = {
    set_currentUser(state,user) {
        state.currentUser = user
        Storage.set('currentUser',user)
    },
    set_token(state, token) {
        state.token = token
        Storage.set('token', token)
    }
}

export const getters = {
    currentUser:state => state.currentUser,
    token: state => state.token
}