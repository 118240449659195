import url from "../../helpers/url";
import axios from "axios";
import Storage from "@/helpers/storage.js";
import getToken from '../../helpers/getToken'

export const state = {
  entrepreneur: [],
};

export const actions = {
  deleteEntrepreneur({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common["Authorization"] = getToken()
      axios
        .delete(`${url()}/api/blog/delete-entrepreneurship/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            console.log("entrepreneur deleted successfully");
          }
          commit("DELETE_Entrepreneur", id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  DELETE_Entrepreneur(state, id) {
    index = state.entrepreneur.findIndex((entre) => entre.id == id),
    state.entrepreneur.splice(index, 1);
  },
};
