import url from "../../helpers/url";
import axios from "axios";
import Storage from "@/helpers/storage.js";
import getToken from '../../helpers/getToken'

export const state = {
  events: [],
};

export const actions = {
  deleteEvent({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common["Authorization"] = getToken()
      axios
        .delete(`${url()}/api/blog/delete-current-event/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            console.log("event deleted successfully");
          }
          commit("DELETE_EVENT", id);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  DELETE_EVENT(state, id) {
    index = state.events.findIndex((event) => event.id == id);
    state.events.splice(index, 1);
  },
};
